.header {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 5px 0;
    background: var(--bg-contrast);
    z-index: 2;
}

.headerContainer {
    max-width: 1530px;
    margin: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.containerInput {
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.searchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color);
    border-radius: 50% 0 0 50%;
    width: 40px;
    height: 40px;
}

.searchIcon {
    width: 25px;
    height: 25px;
}

.input {
    background-color: rgba(255, 255, 255, 0.918);
    height: 40px;
    padding-left: 15px;
    border-radius: 0 50px 50px 0;
    border: none;
    width: 450px;
    font-size: 16px;
}

input:focus {
    outline: none;
}

.containerMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #292929;
}

.iconMenu {
    height: 30px;
    margin-left: 30px;
}

.iconMenu:hover {
    scale: 1.03;
    transition: all 300ms;
}

.filter {
    width: 25px;
}

.filterButton {
    margin-left: 30px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.filterConatiner {
    position: absolute;
    width: auto;
    top: 30vh;
    left: 36%;
    border-radius: 2vw;
    padding: 2vw;
    background-color: var(--bg-color);
    z-index: 2;
    font-size: 1.3vw;
    color: var(--bg-contrast);
    transition: all 200ms;
}

.filterConatiner hr {
    margin: 1vw 0;
    background-color: var(--bg-light);
    border: none;
    height: 1px;
}

.filterDiv {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1vw;
    margin-top: 1.5vw;
}

.filterDiv button {
    width: 3vw;
    height: 3vw;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    transition: all 300ms;
}

.filterDiv button:hover {
    scale: 1.05;
    transition: all 300ms;
}

.filterDiv button:focus {
    outline: 1px solid #55ec6f;
    box-shadow: 0 0 10px 5px #4cd463af;
}

.filterDiv img {
    width: 100%;
    height: 100%;
}


.filterImg {
    width: 1.2vw;
    fill: var(--bg-light);
}

.closeImg {
    width: 1.2vw;
    height: fit-content;
    fill: var(--bg-light);
    position: relative;
    left: 1.2vw;
    opacity: 0;
}

.cleanFilterImg {
    width: 1.3vw;
    height: fit-content;
    fill: var(--bg-light);
    position: relative;
    transition: transform 0.5s ease;
}

.cleanFilterImg:hover {
    transform: rotate(360deg);
}

.cleanFilterButton {
    cursor: pointer;
}

.filterTitleDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filterButtons button {
    background: none;
    border: none;
    cursor: pointer;
    transition: all 300ms;
}

.filterButtons button:hover >  .closeImg {
    opacity: 1;
}

.filterButtons button:hover >  .filterImg {
    opacity: 0;
}

.backgroundFilter {
    position: absolute;
    left: 0;
    top:0;
    width: 110vw;
    height: 100vh;
    background: #07011eca;
    z-index: 1;
}


/* Responsive */

/* From 1200px to 1460px */
@media (min-width: 1200px) and (max-width: 1460px) {
    .header {
        padding: 5px 0;
    }
    
    .headerContainer {
        max-width: 1150px;
    }
    
    .containerInput {
        margin: 18px 0;
    }
    
    .searchContainer {
        width: 35px;
        height: 35px;
    }
    
    .searchIcon {
        width: 20px;
        height: 20px;
    }
    
    .input {
        height: 35px;
        padding-left: 12px;
        width: 420px;
        font-size: 14px;
    }
    
    .iconMenu {
        height: 25px;
        margin-left: 25px;
    }
    
    .filter {
        width: 20px;
    }
    
    .filterButton {
        margin-left: 25px;
    }
    
    .filterConatiner {
        top: 25vh;
        left: 35%;
        border-radius: 2vw;
        padding: 2vw;
        font-size: 22px;
    }
    
    .filterConatiner hr {
        margin: 1vw 0;
    }
    
    .filterDiv {
        grid-gap: 1.2vw;
        margin-top: 1.5vw;
    }
    
    .filterDiv button {
        width: 4vw;
        height: 4vw;
    }
    
    .filterDiv img {
        width: 100%;
        height: 100%;
    }
    
    .filterImg {
        width: 1.6vw;
    }
    
    .closeImg {
        width: 1.6vw;
        left: 1.6vw;
    }
    
    .cleanFilterImg {
        width: 1.6vw;
    }
    
    .backgroundFilter {
        width: 110vw;
        height: 100vh;
    }
}
  
/* From 992px to 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
    .header {
        padding: 5px 0;
    }
    
    .headerContainer {
        max-width: 980px;
    }
    
    .containerInput {
        margin: 18px 0;
    }
    
    .searchContainer {
        width: 35px;
        height: 35px;
    }
    
    .searchIcon {
        width: 20px;
        height: 20px;
    }
    
    .input {
        height: 35px;
        padding-left: 12px;
        width: 420px;
        font-size: 14px;
    }
    
    .iconMenu {
        height: 25px;
        margin-left: 25px;
    }
    
    .filter {
        width: 20px;
    }
    
    .filterButton {
        margin-left: 25px;
    }
    
    .filterConatiner {
        top: 25vh;
        left: 35%;
        border-radius: 2vw;
        padding: 2vw;
        font-size: 22px;
    }
    
    .filterConatiner hr {
        margin: 1vw 0;
    }
    
    .filterDiv {
        grid-gap: 1.2vw;
        margin-top: 1.5vw;
    }
    
    .filterDiv button {
        width: 4vw;
        height: 4vw;
    }
    
    .filterDiv img {
        width: 100%;
        height: 100%;
    }
    
    .filterImg {
        width: 1.6vw;
    }
    
    .closeImg {
        width: 1.6vw;
        left: 1.6vw;
    }
    
    .cleanFilterImg {
        width: 1.6vw;
    }
    
    .backgroundFilter {
        width: 110vw;
        height: 100vh;
    }
}

/* From 768px to 991px */
@media (min-width: 768px) and (max-width: 991px) {
    .header {
        padding: 5px 0;
    }
    
    .headerContainer {
        max-width: 700px;
    }
    
    .containerInput {
        margin: 18px 0;
    }
    
    .searchContainer {
        width: 25px;
        height: 25px;
    }
    
    .searchIcon {
        width: 15px;
        height: 15px;
    }
    
    .input {
        height: 25px;
        padding-left: 12px;
        width: 300px;
        font-size: 12px;
    }
    
    .iconMenu {
        height: 20px;
        margin-left: 20px;
    }
    
    .filter {
        width: 15px;
    }
    
    .filterButton {
        margin-left: 25px;
    }
    
    .filterConatiner {
        top: 25vh;
        left: 28%;
        border-radius: 2vw;
        padding: 3vw;
        font-size: 18px;
    }
    
    .filterConatiner hr {
        margin: 1.5vw 0;
    }
    
    .filterDiv {
        grid-gap: 1.5vw;
        margin-top: 1.5vw;
    }
    
    .filterDiv button {
        width: 5vw;
        height: 5vw;
    }
    
    .filterDiv img {
        width: 100%;
        height: 100%;
    }
    
    .filterImg {
        width: 2vw;
    }
    
    .closeImg {
        width: 2vw;
        left: 2vw;
    }
    
    .cleanFilterImg {
        width: 2vw;
    }
    
    .backgroundFilter {
        width: 110vw;
        height: 100vh;
    }
}

/* From 481px to 767px */
@media (min-width: 569px) and (max-width: 767px) {
    .header {
        padding: 5px 0;
    }
    
    .headerContainer {
        max-width: 550px;
    }
    
    .containerInput {
        margin: 18px 0;
    }
    
    .searchContainer {
        width: 25px;
        height: 25px;
    }
    
    .searchIcon {
        width: 15px;
        height: 15px;
    }
    
    .input {
        height: 25px;
        padding-left: 12px;
        width: 300px;
        font-size: 12px;
    }
    
    .iconMenu {
        height: 20px;
        margin-left: 20px;
    }
    
    .filter {
        width: 15px;
    }
    
    .filterButton {
        margin-left: 25px;
    }
    
    .filterConatiner {
        top: 25vh;
        left: 15%;
        border-radius: 2vw;
        padding: 3.5vw;
        font-size: 20px;
    }
    
    .filterConatiner hr {
        margin: 2vw 0;
    }
    
    .filterDiv {
        grid-gap: 3vw;
        margin-top: 3vw;
    }
    
    .filterDiv button {
        width: 8vw;
        height: 8vw;
    }
    
    .filterDiv img {
        width: 100%;
        height: 100%;
    }
    
    .filterImg {
        width: 3.5vw;
    }
    
    .closeImg {
        width: 3.5vw;
        left: 3vw;
    }
    
    .cleanFilterImg {
        width: 3.5vw;
    }
    
    .backgroundFilter {
        width: 110vw;
        height: 100vh;
    }
}
  
/* Up to 480px */
@media (max-width: 568px) { 
    .header {
        padding: 5px 0;
    }
    
    .headerContainer {
        max-width: 400px;
    }
    
    .containerInput {
        margin: 18px 0;
    }
    
    .searchContainer {
        width: 25px;
        height: 25px;
    }
    
    .searchIcon {
        width: 12px;
        height: 12px;
    }
    
    .input {
        height: 25px;
        padding-left: 8px;
        width: 200px;
        font-size: 12px;
    }
    
    .iconMenu {
        height: 20px;
        margin-left: 20px;
    }
    
    .filter {
        width: 15px;
    }
    
    .filterButton {
        margin-left: 18px;
    }
    
    .filterConatiner {
        top: 25vh;
        left: 9%;
        border-radius: 2vw;
        padding: 5vw;
        font-size: 20px;
    }
    
    .filterConatiner hr {
        margin: 2.5vw 0;
    }
    
    .filterDiv {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-gap: 3vw;
        margin-top: 3.5vw;
    }
    
    .filterDiv button {
        width: 12vw;
        height: 12vw;
    }
    
    .filterDiv img {
        width: 100%;
        height: 100%;
    }
    
    .filterImg {
        width: 5vw;
    }
    
    .closeImg {
        width: 5vw;
        left: 5vw;
    }
    
    .cleanFilterImg {
        width: 5vw;
    }
    
    .backgroundFilter {
        width: 110vw;
        height: 100vh;
    }
}