.nav {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90vw;
  width: 100%;
  padding: 50px 0;
}

.navRight {
  display: flex;
  align-items: center;
}

.random {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 80px;
}

.random img {
  width: 30px;
}

.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #292929;
  font-size: 1.5vw;
  font-weight: 400;
}

.pokeballButton {
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  background: none;
  font-size: 1.3rem;
  color: var(--bg-color);
  position: relative;
  z-index: 2;
}

.pokeballButton:hover {
  filter: brightness(1.1);
}
  
.pokeballImg {
  width: 30px;
  height: 100%;
  margin-right: 10px;
}

.menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.menuToggle a {
  text-decoration: none;
  color: var(--bg-color);
  transition: all 0.3s ease;
}

.menuToggle a:hover {
  color: tomato;
  scale: 1.1;
}

.menuToggle input {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2; 
  -webkit-touch-callout: none;
}

.menuToggle span {
  display: block;
  width: 30px;
  height: 3px;
  position: relative;
  background: var(--bg-color);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 15% 0;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.menuToggle span:first-child {
  transform-origin: 0% 0%;
}

.menuToggle span:nth-child(-n+3) {
  margin-bottom: 5px;
}

.menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-5%, -10%);
  background: var(--bg-color);
}

.menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -10%);
}

.menu {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 110%;
  height: 110%;
  overflow: hidden;
  right: -3vw;
  top: -4vw;
  padding: 3vw 10vw ;
  background: var(--bg-contrast);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.menuToggle input:checked ~ ul {
  transform: none;
}

.iconMenu {
  height: 3vw;
  margin-bottom: .5vw;
}

/* Responsive */

@media (min-width: 992px) and (max-width: 1199px) {
  .navLink {
    font-size: 1.5vw;
  }

  .navContainer {
    max-width: 90vw;
    padding: 30px 0;
  }

  .pokeballButton {
    font-size: 1rem;
  }

  .pokeballImg {
    width: 25px;
    margin-right: 10px;
  }

  .random {
    padding-right: 70px;
  }
  
  .random img {
    width: 25px;
  }

  .menuToggle input {
    display: block;
    width: 30px;
    height: 30px;
  }
  
  .menuToggle span {
    width: 30px;
    height: 3px;
  }
}

@media (min-width: 768px) and (max-width: 991px) { 
  .navLink {
    font-size: 1.8vw;
    font-weight: 400;
  }

  .navContainer {
    max-width: 90vw;
    padding: 30px 0;
  }

  .iconMenu {
    margin-bottom: 1.5vw;
  }

  .pokeballButton {
    font-size: 1rem;
  }

  .pokeballImg {
    width: 20px;
    margin-right: 10px;
  }

  .random {
    padding-right: 70px;
  }
  
  .random img {
    width: 25px;
  }

  .menuToggle input {
    display: block;
    width: 30px;
    height: 30px;
  }
  
  .menuToggle span {
    width: 30px;
    height: 3px;
  }
}

/* For Mobile Portrait View */

@media (min-width: 481px) and (max-width: 767px) {

  .navLink {
    font-size: 3vw;
    font-weight: 400;
  }

  .navContainer {
    max-width: 90vw;
    width: 100%;
    padding: 25px 0;
  }

  .iconMenu {
    height: 8vw;
    margin-bottom: 2vw;
  }

  .pokeballButton {
    font-size: 1rem;
  }

  .pokeballImg {
    width: 20px;
    margin-right: 10px;
  }

  .random {
    padding-right: 70px;
  }
  
  .random img {
    width: 25px;
  }

  .menuToggle input {
    display: block;
    width: 30px;
    height: 30px;
  }
  
  .menuToggle span {
    width: 30px;
    height: 3px;
  }
}

@media (max-width: 480px) {

  .navLink {
    font-size: 3vw;
    font-weight: 400;
  }

  .navContainer {
    max-width: 90vw;
    width: 100%;
    padding: 25px 0;
  }

  .iconMenu {
    height: 8vw;
    margin-bottom: 2vw;
  }

  .pokeballButton {
    font-size: 1rem;
  }

  .pokeballImg {
    width: 20px;
    margin-right: 10px;
  }

  .random {
    padding-right: 70px;
  }
  
  .random img {
    width: 25px;
  }

  .menuToggle input {
    display: block;
    width: 30px;
    height: 30px;
  }
  
  .menuToggle span {
    width: 30px;
    height: 3px;
  }
}