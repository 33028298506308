.div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
}

.modalContent {
    background: rgba(255, 255, 255, 0.2);
    padding: 15px 30px 30px 30px; 
    border-radius: 1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    text-align: center;
    max-width: 500px;
    width: 90%;
    position: relative;
    color: var(--bg-color);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.modalIcon {
    font-size: 45px;
    color: #facc15;
}
  
.modalTitle {
    font-size: 32px;
    font-weight: bold;
    margin: 15px 0 10px 0;
}
  
.modalText {
    font-size: 18px;
    color: var(--bg-light);
    margin-bottom: 20px;
}
  
.modalButton {
    font-size: 18px;
    background-color: var(--accent-color);
    color: white;
    padding: 10px 20px;
    width: 100%;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.2s;
}
  
.modalButton:hover {
    background-color: #d83126;
}

.heart {
    position: absolute;
    font-size: 24px;
    color: red;
    animation: float 1s ease-out forwards;
}

@keyframes float {
    0% { transform: translateY(0) scale(1); opacity: 1; }
    100% { transform: translateY(-100px) scale(1.5); opacity: 0; }
}

@media (max-width: 568px) { 
    .modalContent {
        padding: 15px 20px 20px 20px; 
    }
    .modalIcon {
        font-size: 35px;
    }
    .modalTitle {
        font-size: 25px;
    }
    .modalText {
        font-size: 15px;
    }
    .modalButton {
        font-size: 16px;
    }
}