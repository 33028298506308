@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:wght@400;500;800&family=Open+Sans:wght@800&display=swap');
@import 'animate.css';

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --bg-color: #fafafa;
  --bg-gradient: linear-gradient(105deg, rgb(41, 42, 44) 0%, rgb(34, 30, 45) 100%);
  --bg-contrast: #151618;
  --bg-secondary: #181f2c;
  --bg-light: #d4d4d4;
  --accent-color: #f44336;
}

html, .app, #root {
  height: 100%;
}

body {
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  height: 100%;
  overflow-x: hidden;
  background: url(../src/assets/background-map.jpg) no-repeat center/cover fixed;
}

/* ancho y altura de la scrollbar */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* fondo transparente */
::-webkit-scrollbar-track {
  background-color: var(--bg-contrast);
}

/* barra de desplazamiento gris oscuro */
::-webkit-scrollbar-thumb {
  background-color: #525D80;
}

/* Responsive */