.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 6vw;
}

.mainContainer {
  max-width: 2000px;
  height: 100%;
}

.pokeLogo {
  height: 50vw;
  position: fixed;
  right: 0;
  transform: translateX(45%);
  top: 0;
  z-index: 1;
  opacity: 0.2;
}

.pokemon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  position: relative;
  z-index: 1;
  min-width: 1200px;
}

.containerLeft {
  width: 100%;
  position: relative;
  left: 0;
  height: fit-content;
}

.divInfo {
  width: 100%;
}

.divImgMobile {
  display: none;
}

.pokeNumber, .pokeNumberMobile {
  position: relative;
  top: -2vw;
  font-family: 'Archivo Black', sans-serif;
  font-size: 14vw;
  font-weight: 900;
  color: rgba(255, 255, 255, .1);
}

.pokemonTitle, .pokemonTitleMobile {
  position: absolute;
  top: 10vw;
  font-size: 5vw;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 50px #52525244;
}

.statsInfo {
  position: relative;
  top: -2vw;
  display: flex;
  justify-content: space-between;
}

.containerStats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.divStatsBars {
  width: 80%;
}

.divStatBar {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  --animate-duration: .3s;
}

.stat {
  flex-shrink: 0;
  min-width: 100px;
  display: flex;
  text-align: right;
  font-size: 1.2rem;
  font-weight: 200;
}

.pts {
  min-width: 100px;
  font-size: 1.2rem;
  margin-left: 20px;
}

progress[value] {
  width: stretch;
  margin-left: .4vw;
  height: 15px;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 10px;
  background: #acacac;
  border: none;
}

progress[value]::-webkit-progress-bar {
  background: #acacac;
  border-radius: 10px;
}

progress[value]::-moz-progress-bar {
  background: white;
  border-radius: 10px;
}

progress[value]::-webkit-progress-value {
  background: white;
  border-radius: 10px;
}

.divStats {
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
  height: fit-content;
  font-size: 1.2rem;
  padding: 20px 50px;
}

.divStat {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.divStats > :first-child,.divStats > :nth-child(2) {
  border-right: 1px solid white;
}

.divStats > :first-child {
  width: 70%;
}

.divStats > :nth-child(2) {
  width: 100%;
}

.divStats > :nth-child(3) {
  width: 70%;
}

.spanStat {
  margin-top: 1vw; 
}

.containerRight {
  display: flex;
  height: 100%;
  padding: 10px 0;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  right: 0;
  top: -1vw;
}

.pokemonInfoImg {
  width: 25vw;
  filter: drop-shadow(10px 2px 10px rgba(0, 0, 0, 0.1));
}

.spanTS {
  height: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spanTS img {
  width: 2.2vw;
  margin: 0 20px;
}


.divAbout {
  width: 80%;
  max-width: 50vw;
}

.containerStatsText {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 40px;
}

.divText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  text-align: left;
  width: 100%;
  min-height: 4vw;
  padding: 10px 30px;
  background-color: aliceblue;
  clip-path: polygon(0% 0%, calc(100% - 20px) 0, 100% 20px, 100% 100%, 95% 100%, calc(0% + 20px) 100%, 0% calc(100% - 20px), 0% calc(100% + 20px));
  border-top: 1px solid var(--accent-color);
  border-left: 20px solid var(--accent-color);
}

.spanAbout {
  display: flex;
  clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 100%, 0% 100%);
  background-color: var(--accent-color);
  padding: 8px 28px 8px 18px;
  color: white;
  width: fit-content;
  font-size: 1.1rem;
  font-weight: 800;
}

.spanText {
  font-size: 1vw;
  line-height: 1.4vw;
  z-index: 2;
  padding-bottom: 10px;
}

.divButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  left: 0;
  top: 50%;
  z-index: 2;
}

.buttonNext {
  transform: rotate(-90deg);
  background: none;
  border: none;
  border-radius: 10px 10px 0 0;
  padding: 15px 10px 25px 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
  background-color: var(--accent-color);
}

.buttonPrev {
  transform: rotate(90deg);
  background: none;
  border: none;
  padding: 15px 10px 25px 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  background-color: var(--accent-color);
}

.buttonPrev:hover, .buttonNext:hover {
  scale: 105%;
  transition: all 100ms ease-in-out;
}

.arrow {
  width: 2vw;
}

/* Color by Type */

.grass {
  background: rgb(158,199,76);
  background: linear-gradient(347deg, rgba(158,199,76,1) 0%, rgb(46, 112, 58) 100%);
}
.fire {
  background: rgb(238,129,48);
  background: linear-gradient(347deg, rgba(238,129,48,1) 0%, rgba(181,53,9,1) 100%);
}
.water {
  background: rgb(99,144,240);
  background: linear-gradient(347deg, rgb(107, 172, 222) 0%, rgb(53, 68, 176) 100%);
}
.normal {
  background: rgb(168,167,122);
  background: linear-gradient(347deg, rgb(168, 156, 122) 0%, rgb(126, 86, 54) 100%);
}
.electric {
  background: rgb(247,208,44);
  background: linear-gradient(347deg, rgb(210, 185, 87) 0%, rgb(218, 153, 79) 100%);
}
.ice {
  background: rgb(150,217,214);
  background: linear-gradient(347deg, rgba(150,217,214,1) 0%, rgba(67,129,177,1) 100%);
}
.fighting {
  background: rgb(176,66,61);
  background: linear-gradient(347deg, rgba(176,66,61,1) 0%, rgba(140,59,95,1) 100%);
}
.poison {
  background: rgb(163,62,161);
  background: linear-gradient(347deg, rgba(163,62,161,1) 0%, rgba(112,43,167,1) 100%);
}
.ground {
  background: rgb(222,189,121);
  background: linear-gradient(347deg, rgba(222,189,121,1) 0%, rgba(210,98,19,1) 100%);
}
.flying {
  background: rgb(169,143,243);
  background: linear-gradient(347deg, rgba(169,143,243,1) 0%, rgba(155,59,204,1) 100%);
}
.psychic {
  background: rgb(227,135,163);
  background: linear-gradient(347deg, rgba(227,135,163,1) 0%, rgba(218,56,105,1) 100%);
}
.bug {
  background: rgb(166,185,26);
  background: linear-gradient(347deg, rgba(166,185,26,1) 0%, rgba(140,128,42,1) 100%);
}
.rock {
  background: rgb(154,147,111);
  background: linear-gradient(347deg, rgba(154,147,111,1) 0%, rgba(86,93,55,1) 100%);
}
.ghost {
  background: rgb(115,87,151);
  background: linear-gradient(347deg, rgba(115,87,151,1) 0%, rgba(57,56,126,1) 100%);
}
.dragon {
  background: rgb(232,85,71);
  background: linear-gradient(347deg, rgba(232,85,71,1) 0%, rgba(179,59,98,1) 100%);
}
.dark {
  background: rgb(136,76,68);
  background: linear-gradient(347deg, rgb(127, 82, 113) 0%, rgb(83, 43, 83) 100%);
}
.steel {
  background: rgb(183,183,206);
  background: linear-gradient(347deg, rgba(183,183,206,1) 0%, rgba(140,118,173,1) 100%);
}
.fairy {
  background: rgb(213,156,185);
  background: linear-gradient(347deg, rgba(213,156,185,1) 0%, rgba(201,117,151,1) 100%);
}

/* Responsive */

@media (min-width: 992px) and (max-width: 1299px) { 
  .main {
    padding: 0 50px;
  }
  
  .mainContainer {
    max-width: 1000px;
    height: 100%;
  }
  
  .pokeLogo {
    height: 65vw;
  }
  
  .pokemon {
    min-width: 800px;
  }
  
  .containerLeft {
    width: 100%;
  }
  
  .divInfo {
    width: 100%;
  }
  
  .pokeNumber {
    top: 0vw;
    margin: 0;
    font-size: 14vw;
  }
  
  .pokemonTitle {
    top: 11vw;
    font-size: 4vw;
  }

  .statsInfo {
    top: -2vw;
  }
  
  .containerStats {
    width: 100%;
  }
  
  .divStatsBars {
    width: 100%;
  }
  
  .divStatBar {
    margin-top: 10px;
    width: 100%;
    gap: 10px;
  }
  
  .stat {
    min-width: 80px;
    font-size: .9rem;
  }
  
  .pts {
    min-width: 100px;
    font-size: .9rem;
    margin-left: 15px;
  }
  
  progress[value] {
    margin-left: .3vw;
    height: 9px;
    border-radius: 15px;
  }
  
  progress[value]::-webkit-progress-bar {
    border-radius: 15px;
  }
  
  progress[value]::-moz-progress-bar {
    border-radius: 15px;
  }
  
  progress[value]::-webkit-progress-value {
    border-radius: 15px;
  }
  
  .divStats {
    font-size: 1rem;
    padding: 10px 40px;
  }
  
  .divStat {
    padding: 0 15px;
  }
  
  .divStats > :first-child {
    width: 70%;
  }
  
  .divStats > :nth-child(2) {
    width: 100%;
  }
  
  .divStats > :nth-child(3) {
    width: 70%;
  }
  
  .spanStat {
    margin-top: 1vw; 
  }
  
  .containerRight {
    top: 0;
  }
  
  .pokemonInfoImg {
    width: 30vw;
  }
  
  .spanTS {
    height: 1vw;
  }
  
  .spanTS img {
    width: 2.5vw;
    margin: 0 10px;
  }
  
  .divAbout {
    width: 90%;
    max-width: 40vw;
  }

  .spanAbout {
    padding: 5px 25px 5px 15px;
    font-size: .8rem;
  }
  
  .spanText {
    font-size: 1vw;
    line-height: 1.4vw;
    padding-bottom: 5px;
  }
  
  .containerStatsText {
    margin-top: 30px;
  }
  
  .divText {
    padding: 10px 20px;
  }
  
  .divButtons {
    width: 100%;
    left: 0;
    top: 50%;
  }
  
  .buttonPrev:hover, .buttonNext:hover {
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  
  .arrow {
    width: 3vw;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .main {
    padding: 80px 100px;
    min-height: 100vh;
  }
  
  .mainContainer {
    max-width: 800px;
    height: 100%;
  }
  
  .pokeLogo {
    height: 65vw;
  }
  
  .pokemon {
    min-width: 650px;
  }
  
  .containerLeft {
    width: 100%;
  }
  
  .divInfo {
    width: 100%;
  }
  
  .pokeNumber {
    
    margin: 0;
    font-size: 14vw;
  }
  
  .pokemonTitle {
    
    font-size: 4vw;
  }
  
  .containerStats {
    width: 100%;
  }
  
  .divStatsBars {
    width: 100%;
  }
  
  .divStatBar {
    margin-top: 5px;
    width: 100%;
    gap: 5px;
  }
  
  .stat {
    min-width: 60px;
    font-size: .9rem;
  }
  
  .pts {
    min-width: 70px;
    font-size: .9rem;
    margin-left: 15px;
  }
  
  progress[value] {
    margin-left: .3vw;
    height: 7px;
    border-radius: 15px;
  }
  
  progress[value]::-webkit-progress-bar {
    border-radius: 15px;
  }
  
  progress[value]::-moz-progress-bar {
    border-radius: 15px;
  }
  
  progress[value]::-webkit-progress-value {
    border-radius: 15px;
  }
  
  .divStats {
    font-size: .9rem;
    padding: 10px 20px;
  }
  
  .divStat {
    padding: 0 15px;
  }
  
  .divStats > :first-child {
    width: 70%;
  }
  
  .divStats > :nth-child(2) {
    width: 100%;
  }
  
  .divStats > :nth-child(3) {
    width: 70%;
  }
  
  .spanStat {
    margin-top: 1vw; 
  }
  
  .containerRight {
    top: 0;
  }
  
  .pokemonInfoImg {
    width: 38vw;
  }
  
  .spanTS {
    height: .9vw;
  }
  
  .spanTS img {
    width: 2.5vw;
    margin: 0 12px;
  }
  
  .divAbout {
    width: 100%;
    max-width: 42vw;
  }

  .spanAbout {
    padding: 5px 25px 5px 15px;
    font-size: .8rem;
  }
  
  .spanText {
    font-size: 1.2vw;
    line-height: 1.8vw;
    padding-bottom: 5px;
  }
  
  .containerStatsText {
    margin-top: 25px;
  }
  
  .divText {
    padding: 8px 20px;
  }
  
  .divButtons {
    width: 100%;
    left: 0;
    top: 50%;
  }
  
  .buttonPrev:hover, .buttonNext:hover {
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  
  .arrow {
    width: 3vw;
  } 
}

@media (min-width: 481px) and (max-width: 766px) {

  
  .mainContainer {
    max-width: 700px;
    height: 100%;
  }

  .pokemon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 470px;
  }

  .pokeLogo {
    height: 80vw;
    right: 0;
    transform: translateX(50%);
    top: 0;
  }
  
  .pokeNumber {
    display: none;
  }

  .pokeNumberMobile {
    position: relative;
    top: 0;
    font-size: 18vw;
  }

  .divImgMobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .pokemonImgMobile {
    position: relative;
    top: 0;
    width: 40%;
    filter: drop-shadow(10px 2px 10px rgba(0, 0, 0, 0.1));
  }

  .divTitleMobile {
    width: 60%;
    position: relative;
    top: 5vw;
  }
  
  .pokemonTitle {
    display: none;
  }

  .pokemonTitleMobile {
    position: relative;
    font-size: 6vw;
    top: -8.5vw;
  }
  
  .statsInfo {
    top: 0;
  }
  
  .containerStats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .divStatsBars {
    width: 100%;
  }
  
  .divStatBar {
    margin-top: 8px;
    width: 100%;
    gap: 0;
  }
  
  .stat {
    min-width: 70px;
    font-size: 1rem;
  }
  
  .pts {
    min-width: 70px;
    font-size: 1rem;
    margin-left: 20px;
  }
  
  progress[value] {
    margin-left: 0;
    height: 10px;
  }
  
  .divStats {
    font-size: 1rem;
    padding: 20px 50px;
  }
  
  .divStat {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
  
  .divStats > :first-child {
    width: 70%;
  }
  
  .divStats > :nth-child(2) {
    width: 100%;
  }
  
  .divStats > :nth-child(3) {
    width: 70%;
  }
  
  .spanStat {
    margin-top: 5px; 
  }
  
  .containerRight {
    right: 0;
    top: -1vw;
  }
  
  .pokemonInfoImg {
    display: none;
  }
  
  .spanTS {
    height: 15px;
  }
  
  .spanTS img {
    width: 5vw;
    margin: 0 8px;
  }
  
  
  .divAbout {
    width: 100%;
    max-width: 100%;
  }
  
  .containerStatsText {
    margin: 30px 0;
    width: 100%;
  }
  
  .divText {
    width: 100%;
    min-height: 4vw;
    padding: 10px 25px;
  }
  
  .spanAbout {
    padding: 5px 25px 5px 15px;
    font-size: .9rem;

  }
  
  .spanText {
    font-size: 2.5vw;
    line-height: 3.5vw;
    padding-bottom: 5px;
  }
  
    
  .divButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 0;
    bottom: 0;
  }

  .buttonNext {
    right: -6vw;
  }
  
  .buttonPrev {
    left: -6vw;
   
  }
  
  .arrow {
    width: 5vw;
  }
}

@media (min-width: 569px) and (max-width: 767px) {
  .mainContainer {
    max-width: 90vw;
    height: 100%;
  }

  .pokemon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: auto;
  }

  .pokeLogo {
    height: 80vw;
    right: 0;
    transform: translateX(50%);
    top: 0;
  }
  
  .pokeNumber {
    display: none;
  }

  .pokeNumberMobile {
    position: relative;
    top: 0;
    font-size: 18vw;
  }

  .divImgMobile {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .pokemonImgMobile {
    position: absolute;
    top: 1vw;
    right: 0;
    width: 40%;
    filter: drop-shadow(10px 2px 10px rgba(0, 0, 0, 0.1));
  }

  .divTitleMobile {
    width: 60%;
    position: relative;
    top: 6vw;
  }
  
  .pokemonTitle {
    display: none;
  }

  .pokemonTitleMobile {
    position: relative;
    font-size: 6vw;
    top: -8.5vw;
  }
  
  .statsInfo {
    top: 0;
  }
  
  .containerStats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .divStatsBars {
    width: 100%;
  }
  
  .divStatBar {
    margin-top: 8px;
    width: 100%;
    gap: 0;
  }
  
  .stat {
    min-width: 70px;
    font-size: 1rem;
  }
  
  .pts {
    min-width: 70px;
    font-size: 1rem;
    margin-left: 20px;
  }
  
  progress[value] {
    margin-left: 0;
    height: 10px;
  }
  
  .divStats {
    font-size: 1rem;
    padding: 20px 50px;
  }
  
  .divStat {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
  
  .divStats > :first-child {
    width: 70%;
  }
  
  .divStats > :nth-child(2) {
    width: 100%;
  }
  
  .divStats > :nth-child(3) {
    width: 70%;
  }
  
  .spanStat {
    margin-top: 5px; 
  }
  
  .containerRight {
    right: 0;
    top: -1vw;
  }
  
  .pokemonInfoImg {
    display: none;
  }
  
  .spanTS {
    height: 15px;
  }
  
  .spanTS img {
    width: 5vw;
    margin: 0 8px;
  }
  
  
  .divAbout {
    width: 100%;
    max-width: 100%;
  }
  
  .containerStatsText {
    margin: 30px 0;
    width: 100%;
  }
  
  .divText {
    width: 100%;
    min-height: 4vw;
    padding: 10px 25px;
  }
  
  .spanAbout {
    padding: 5px 25px 5px 15px;
    font-size: .9rem;

  }
  
  .spanText {
    font-size: 2.5vw;
    line-height: 3.5vw;
    padding-bottom: 5px;
  }
  
  .divButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 0;
    bottom: 0;
  }

  .buttonNext {
    right: -5vw;
  }
  
  .buttonPrev {
    left: -5vw;
   
  }
  
  .arrow {
    width: 6vw;
  }
}

@media (max-width: 480px) { 
  .mainContainer {
    max-width: 90vw;
    height: 100%;
  }

  .pokemon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: auto;
  }

  .pokeLogo {
    height: 80vw;
    right: 0;
    transform: translateX(50%);
    top: 0;
  }
  
  .pokeNumber {
    display: none;
  }

  .pokeNumberMobile {
    position: relative;
    top: 0;
    font-size: 18vw;
  }

  .divImgMobile {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .pokemonImgMobile {
    position: absolute;
    top: -20vw;
    right: 0;
    width: 60%;
    filter: drop-shadow(10px 2px 10px rgba(0, 0, 0, 0.1));
  }

  .divTitleMobile {
    width: 60%;
    position: relative;
    top: 5vw;
  }
  
  .pokemonTitle {
    display: none;
  }

  .pokemonTitleMobile {
    position: relative;
    font-size: 6vw;
    top: -8.5vw;
  }
  
  .statsInfo {
    top: 0;
  }
  
  .containerStats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .divStatsBars {
    width: 100%;
  }
  
  .divStatBar {
    margin-top: 8px;
    width: 100%;
    gap: 0;
  }
  
  .stat {
    min-width: 70px;
    font-size: 1rem;
  }
  
  .pts {
    min-width: 70px;
    font-size: 1rem;
    margin-left: 20px;
  }
  
  progress[value] {
    margin-left: 0;
    height: 10px;
  }
  
  .divStats {
    font-size: 1rem;
    padding: 20px 50px;
  }
  
  .divStat {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
  
  .divStats > :first-child {
    width: 70%;
  }
  
  .divStats > :nth-child(2) {
    width: 100%;
  }
  
  .divStats > :nth-child(3) {
    width: 70%;
  }
  
  .spanStat {
    margin-top: 5px; 
  }
  
  .containerRight {
    right: 0;
    top: -1vw;
  }
  
  .pokemonInfoImg {
    display: none;
  }
  
  .spanTS {
    height: 15px;
  }
  
  .spanTS img {
    width: 5vw;
    margin: 0 8px;
  }
  
  
  .divAbout {
    width: 100%;
    max-width: 100%;
  }
  
  .containerStatsText {
    margin: 30px 0;
    width: 100%;
  }
  
  .divText {
    width: 100%;
    min-height: 4vw;
    padding: 10px 25px;
  }
  
  .spanAbout {
    padding: 5px 25px 5px 15px;
    font-size: .9rem;

  }
  
  .spanText {
    font-size: 2.5vw;
    line-height: 3.5vw;
    padding-bottom: 5px;
  }
  
  .divButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 0;
    bottom: 0;
  }

  .buttonNext {
    right: -5vw;
  }
  
  .buttonPrev {
    left: -5vw;
   
  }
  
  .arrow {
    width: 6vw;
  }
}

